import React from 'react';
import axios from "axios";
import { Switch, Route, Redirect } from 'react-router-dom'

import Loading from '../common/loading';
import parseQueryParam from '../../utils/parseQueryParams';
import AppLogout from './autoLogout';

import Login from './login';
import Signup from './signup';
import EmailVerification from './emailVerification';
import PendingApproval from './pendingApproval';
import ResetPassword from './resetPassword'
import EnterpriseSetup from './enterpriseSetup';
import ConfirmEmail from './confirmEmail';

import NotificationManager from '../../utils/notificationManager';
import ModalManager from '../common/modal/ModalManager'

// public pages
import Pricing from './pricing';
import BetaTerms from './betaTerms';


const DEFAULT_LOGIN_TIME = 2100;

function authWrapper(WrappedComponent) {
  return class extends React.Component {
    state = {
      authorized: false,
      userLoading: false,
      user: null,
      errMessage: "",
      signupErrMessage: "",
    }
    componentDidMount() {
      this.processLocalStorageOrQueryParameters();
    }

    /**
     * You can prelogin with 2 separate ways
     * 1. Query Parameters
     *  - t: token
     *  - u: userId
     *  - These are available only through SAML SSO Redirect from iDP initiated login flows. They use the default login time.
     * 2. Local Storage
     *  - token
     *  - userId
     *  - tokenExpiration
     * 
     * when sucessfully authenticating either, a token is stored, a token expiration time, and if logged in with query parameter/saml then a  redirect login url is made
     * 
     * 
     */
    processLocalStorageOrQueryParameters = () => {
      const { history } = this.props;
      const cookie = localStorage.getItem("token");
      const token = parseQueryParam(history.location.search).t;
      const userId = parseQueryParam(history.location.search).u;
      if (([
        "/register",
        "/reset",
        "/confirm-email",
        "/pricing",
        "/enterprise-setup"
      ].includes(history.location.pathname))) {
        // ignore register for auth
        if ((history.location.pathname === "/confirm-email") && localStorage.getItem("userId")) {
          this.fetchUser()
        }
      } else {
        if (token) { // assume if there is a token in the query parameters that expiration time is 30s
          // console.log("PROCESSING QUERY PARAMETER TOKEN FROM SAML")
          localStorage.setItem("token", token)
          localStorage.setItem("loginMethod", "saml")
          localStorage.setItem("tokenExpiration", new Date().getTime() + DEFAULT_LOGIN_TIME*1000);
          localStorage.setItem('userId', userId)
          this.fetchUser((err, pkg) => {
            if (err) { // USER IS TRYING TO LOGIN WITH A FAULTY SAML TOKEN IN THE QUERY PARAMETER
              // console.log("SAML token is not valid")
              this.props.history.replace(`/login?redirect=${this.props.history.location.pathname === "/logout" ? "/" : this.props.history.location.pathname}`)
            } else { // USER IS LOGGING IN WITH A SAML TOKEN IN THE QUERY PARAMETER
              const user = pkg.data;
              // console.log("SAML token is valid.")
              localStorage.setItem("samlLoginUrl", user.enterprise.SAML_LOGIN_URL)
              this.loginTimeoutModal(DEFAULT_LOGIN_TIME*1000) // logged in successfully with saml
            }
          })
        } else if (cookie) {

          
          this.fetchUser((err, user) => {
            if (err) { // USER IS TRYING TO LOGIN WITH A FAULTY LOCAL STORAGE COOKIE
              if (err.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("tokenExpiration");
                localStorage.removeItem("userId");

                this.setState({
                  authorized: false,
                  // user: null
                })
                // console.log("CLEARING LOCAL STORAGE")
              }
              const samlLoginurl = localStorage.getItem("samlLoginUrl");
              if (samlLoginurl) { // USER IS TRYING TO LOGIN WITH A FAULTY LOCAL STORAGE COOKIE FROM SAML
                // window.location = this.state.user.enterprise.SAML_LOGIN_URL
                window.location = samlLoginurl;
              } else { // USER IS TRYING TO LOGIN WITH A FAULTY LOCAL STORAGE COOKIE FROM PASSWORD LOGIN
                // console.log("REDIRECTINBG TO ", `/login?redirect=${this.props.history.location.pathname === "/logout" ? "/" : this.props.history.location.pathname}`)
                this.props.history.replace(`/login?redirect=${this.props.history.location.pathname === "/logout" ? "/" : this.props.history.location.pathname}`)
              }
            } else { // USER IS LOGGING IN WITH A GOOD LOCAL STORAGE COOKIE
              const tokenExpiration = localStorage.getItem("tokenExpiration");
              if (tokenExpiration) { 
                const timeUntilExpiration = parseInt(tokenExpiration, 10) - new Date().getTime();
                // console.log("TIME UNTIL EXPIRATION", timeUntilExpiration)
                if (timeUntilExpiration > 0) {
                  this.loginTimeoutModal(timeUntilExpiration);
                } else {
                  // console.log("Expired token expiration time, no timeout modal created for later")
                }
              } else {
                // console.log("NO TOKEN EXPIRATION TIME FOUND")
              }
              // this.loginTimeoutModal()
            }
          });

        } else  {
          // console.log("NO LOGIN INFORMATION FOUND")
          // console.log("redirecting because no cookie")


          const { pathname } = history.location
          const redirect = (pathname === "/login" || pathname === "/") ? "" : `?redirect=${pathname}`;
          this.props.history.replace(`/login${redirect}`);

        }
      }
    }

    loginTimeoutModal = (timeoutInMs=DEFAULT_LOGIN_TIME*1000) => {
      this.timer = setTimeout(() => {
        ModalManager.confirm (
          "You have been signed out. Please sign in to access Synaccess Cloud.",
          "Session Expired",
          "Sign In",
          (status, close) => {
            if (status) { // clicked sign in
              const loginMethod = localStorage.getItem("loginMethod");
              
              if (this.state.user && this.state.user.enterprise && this.state.user.enterprise.SAML_LOGIN_URL && loginMethod === "saml") {
                window.location = this.state.user.enterprise.SAML_LOGIN_URL
              } else {
                console.log("Timeout, and Modal selected. Reprocessing credentials to redirect appropriately")
                this.processLocalStorageOrQueryParameters()
                // this.props.history.replace(`/login?redirect=${this.props.history.location.pathname === "/logout" ? "/" : this.props.history.location.pathname}`)
              }
              close();

            } else { // clicked cancel or outside

            }
          },
          null,
          true
        );
      },timeoutInMs)
    }
    updateUser = (obj) => {
      this.setState(obj);
    }

    updateUserFetch = (user, body, cb = () => {}) => {
      axios.put(`/v1/users/${user.id}`, body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }).then(res => {
        cb(null, res.data);
        console.log(res.data.data)
        this.setState({// replace single item in array for resource being edited, search by ID match
          user: res.data.data
        })
      }).catch(err => {
        cb(err, null);
        console.log(err)
      })
    }

    fetchUser = (cb = () => { }) => {
      this.setState({ userLoading: true })
      axios
        .get(`/v1/users/${localStorage.getItem("userId")}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        })
        .then(res => {
          this.setState({ userLoading: false });
          this.setState({
            // users: res.data.data
            authorized: true,
            // ...res.data
            user: res.data.data
          })
          cb(null, res.data)
        })
        .catch(err => {
          this.setState({ userLoading: false });
          cb(err);
          console.log(err)
        })
    }
    fetchLogin = (username, password, cb = () => { }, stayLoggedIn) => {
      this.setState({
        userLoading: true,
        errMessage: ""
      })
      axios
        .post('/v1/auth/login', {
          email: username.trim(),
          password,
          stayLoggedIn
        })
        .then(res => {
          cb()
          this.setState({
            userLoading: false
          })
          if (res.status === 200) {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("tokenExpiration", new Date().getTime() + res.data.tokenExpirationTime * 1000); // set token expiration by taking the current time and adding the expiration time
            localStorage.setItem("userId", res.data.user.user_id);

            // call loginTimeoutModal and calculate the time until the token expires and set that as the parameter
            this.loginTimeoutModal(res.data.tokenExpirationTime * 1000);

            this.setState({
              authorized: true,
              user: res.data.user
            });
            const redirectUrl = parseQueryParam(this.props.history.location.search).redirect;
            this.props.history.replace(redirectUrl || "/");

          } else {
            NotificationManager.alert("Incorrect email or password.");
            this.setState({
              errMessage: "Incorrect email or password."
            })
          }
        }).catch(error => {
          console.log(error)
          cb()
          NotificationManager.alert(`Username and/or Password Invalid. (${error.response.data.message})`);
          this.setState({
            userLoading: false
          })
          if (error.response.status === 401) {
            this.setState({
              // errMessage: "Username and/or Password Invalid."
              errMessage: error.response.data.message === "Unauthorized" ? "Username and/or Password Invalid." : error.response.data.message
            })
          } else if (error.response.status === 400) {
            this.setState({
              errMessage: error.response.data.message
            })
          } else {
            this.setState({
              errMessage: "Error Occurred"
            })

          }
        })
    }
    fetchSignup = ({
      fullName,
      password,
      email,
      company
    },
      cb = () => { }
    ) => {
      this.setState({
        signupErrMessage: ""
      })
      axios
        .post('/v1/auth/register', {
          fullName: fullName,
          password,
          email,
          company
        })
        .then(res => {
          localStorage.setItem("token", res.data.token)
          localStorage.setItem("userId", res.data.user.user_id)
          this.setState({
            authorized: true,
            user: res.data.user
          })
          this.props.history.replace("/email-verification")
        })
        .catch(err => {
          console.log(err)
          cb(err)
          if (err.response) {
            this.setState({
              signupErrMessage: err.response.data.message
            })
          }
        })
    }
    signout = () => {

      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiration");
      localStorage.removeItem("userId");
      this.props.history.replace("/login");
      this.setState({
        authorized: false,
        user: null,
      })
    }

    resendVerificationEmail = (cb = () => { }) => {
      axios
        .post("/v1/auth/resend-verification", {}, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }).then(res => {
          cb(null, res);
          NotificationManager.success("Verification email sent!");
        }).catch(err => {
          console.log(err)
          cb(err, null)
        })

    }
    submitForgottenPassEmail = (email, cb = () => { }) => {
      axios
        .post("/v1/auth/reset", {
          email
        }, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }).then(res => {
          cb(null, res);
        }).catch(err => {

          console.log(err)
          cb(err, null)
        })
    }

    confirmEmail = ({
      token,
      cookie,
      password
    }, cb = () => { }) => {
      axios.post("/v1/auth/confirm-email", { token, cookie, password }, {})
        .then(res => {
          if (res.status === 200) {
            localStorage.setItem("token", res.data.token)
            localStorage.setItem("userId", res.data.data.user_id)
            this.setState({
              authorized: true,
              user: res.data.data
            }, () => {
              cb(null, res);
              this.props.history.replace("/")
            })
          } else {
            cb({ err: "nah" }, null)
          }
        }).catch(err => {
          cb(err, null);
        })
    }
    resetPassword = ({ password, currentPass }, cb = () => {}) => {
      // const token = localStorage.getItem("token") || parseQueryParam(this.props.history.location.search).t
      const token = localStorage.getItem("token");
      const email_token = parseQueryParam(this.props.history.location.search).t
      axios.post("/v1/auth/reset/token", {
        token, 
        email_token,
        password,
        currentPass
      }, {})
        .then(res => {
          if (res.status === 200) {
            cb(null, res.data);
            this.setState({
              user: res.data.data,
              authorized: true
            })
            localStorage.setItem("token", res.data.token)
            localStorage.setItem("userId", res.data.data.user_id)
            
          } else {
            cb(res.data, null);
            console.log("bad response from reset token request")
          }
        })
        .catch(err => {
          cb(err.response.data, null);
          console.log(err)
        })
    }
    setupEnterpriseUser = ({ password, currentPass }, cb = () => {}) => {
      // const token = localStorage.getItem("token") || parseQueryParam(this.props.history.location.search).t
      const token = localStorage.getItem("token");
      const email_token = parseQueryParam(this.props.history.location.search).t
      axios.post("/v1/auth/reset/enterprise-token", {
        token, 
        email_token,
        password,
        currentPass
      }, {})
        .then(res => {
          if (res.status === 200) {
            cb(null, res.data);
            this.setState({
              user: res.data.data,
              authorized: true
            })
            localStorage.setItem("token", res.data.token)
            localStorage.setItem("userId", res.data.data.user_id)
            
          } else {
            cb(res.data, null);
            // console.log("bad response from reset token request")
          }
        })
        .catch(err => {
          cb(err.response.data, null);
          console.log(err)
        })
    }
    render() {
      const { history } = this.props;

      const userId = localStorage.getItem("userId");
      return (
        <Switch>
          <Route
            path="/register"
            render={() => {
              return (
                <Signup
                  fetchSignup={this.fetchSignup}
                  signupErrMessage={this.state.signupErrMessage}
                />
              )
            }}
          />
          <Route
            path="/login"
            render={() => {
              // return null
              const { authorized } = this.state;
              if (authorized) {
                const redirectUrl = parseQueryParam(history.location.search).redirect;
                return (
                  <Redirect to={redirectUrl || "/"} />
                )
              } else {
                return (
                  <Login
                    fetchLogin={this.fetchLogin}
                    errMessage={this.state.errMessage}
                  />
                )
              }
            }}
          />
          <Route
            path="/email-verification"
            render={() => {
              const { authorized } = this.state;
              const user = this.state.user;
              if (user && user.email_verified && authorized) {
                return <Redirect to={"/"} />
              }
              if (!userId) {
                return (
                  <Redirect to={"/login"} />
                )
              }
              if (this.state.user && this.state.user.emailVerified) {
                return <Redirect to="/" />
              }
              return (
                <EmailVerification
                  userId={userId}
                  history={this.props.history}
                  signout={this.signout}
                  resendVerificationEmail={this.resendVerificationEmail}
                />
              )
            }}
          />
          <Route
            path="/pending-approval"
            render={() => {
              const { authorized } = this.state;
              const user = this.state.user;
              if (user && user.approved && authorized) {
                return <Redirect to={"/"} />
              }
              return (
                <PendingApproval
                  userId={userId}
                  signout={this.signout}
                  user={this.state.user}
                />
              )
            }}
          />
          <Route
            path="/confirm-email"
            render={() => {
              if (this.state.userLoading) return <Loading fixed />

              return (
                <ConfirmEmail
                  userLoading={this.state.userLoading}
                  authorized={this.state.authorized}
                  user={this.state.user}
                  confirmEmail={this.confirmEmail}
                  history={this.props.history}
                />
              )
            }}
          />
          <Route
            path="/reset"
            render={() => {
              // const { authorized } = this.state;
              // const user = this.state.user;
              // if (user && user.approved && authorized) {
              //   return <Redirect to={"/"} />
              // }
              return (
                <ResetPassword
                  submitForgottenPassEmail={this.submitForgottenPassEmail}
                  history={this.props.history}
                  resetPassword={this.resetPassword}
                  updateUser={this.updateUser}
                />
              )
            }}
          />
          <Route
            path="/enterprise-setup"
            render={() => {
              return (
                <EnterpriseSetup
                  submitForgottenPassEmail={this.submitForgottenPassEmail}
                  history={this.props.history}
                  setupEnterpriseUser={this.setupEnterpriseUser}
                  updateUser={this.updateUser}
                />
              )
            }}
          />
          <Route
            path="/pricing"
            render={() => {
              return (
                <Pricing
                />
              )
            }}
          />
          <Route
            path="/beta-terms"
            render={() => {
              return (
                <BetaTerms
                />
              )
            }}
          />
          <Route
            render={() => {
              const { authorized } = this.state;
              if (this.state.userLoading) {
                return (
                  <Loading
                    size="40px"
                    black
                    fixed
                  />
                );

              } else if (!authorized) {
                return null;

              } else if (authorized) {
                if (!this.state.user) return null;
                if (!this.state.user.email_verified) {
                  return <Redirect to={"/email-verification"} />
                }
                if (!this.state.user.approved) {
                  return <Redirect to={"/pending-approval"} />
                }
                return (
                  <AppLogout
                    updateUser={this.updateUser}
                    history={history}
                  >
                    <WrappedComponent
                      user={{
                        ...this.state.user,
                      }}
                      updateUser={this.updateUser}
                      updateUserFetch={this.updateUserFetch}
                      resetPassword={this.resetPassword}

                      {...this.props}
                    />
                  </AppLogout>
                );
              } else {
                return (
                  <Redirect to={`/login?redirect=${history.location.pathname === "/logout" ? "/" : history.location.pathname}`} />
                );
              }
            }}
          />
        </Switch>
      );
    }
  }
}

export default authWrapper
import React from 'react';
import axios from "axios";
import NotificationManager from '../utils/notificationManager';

const resourceRef = {
  outlets: 'Outlet',
  inlets: 'Inlet',
  devices: 'Device',
  groups: 'Group',
  enterprises: 'Enterprise',
  events: "Event",
  actions: "Action",
  roles: "Role"
}

function fetchWrapper(WrappedComponent) {
  return class extends React.Component {
    state = {
      outlets: [],
      outletsLoading: false,
      outletsLoaded: false,
      // outletsLastLoaded: null,
      
      inlets: [],
      inletsLoading: false,
      inletsLoaded: false,
      inletsLastLoaded: null,

      devices: [],
      devicesLoading: false,
      devicesLoaded: false,
      // devicesLastLoaded: null,
      
      events: [],
      eventsLoading: false,
      eventsLoaded: false,

      eventItemLoading: false,

      actions: [],
      actionsLoading: false,
      actionsLoaded: false,

      sensors: [],
      sensorsLoading: false,
      sensorsLoaded: false,
      
      banksLoading: false,
      banksLoaded: false,
      banks: [],

      groups: [],
      groupsLoading: false,
      groupsLoaded: false,

      activity: [],
      activityLoading: false,
      activityLoaded: false,

      enterprises: [],
      enterprisesLoading: false,
      enterprisesLoaded: false,

      userEnterprise: {},
      userEnterpriseLoading: false,
      userEnterpriseLoaded: false,
      userEnterpriseUsers: [],

      roles: [],
      rolesLoading: false,
      rolesLoaded: false,
      
    }
    
    fetchResource = (resource, cb = () => {}, queryParam="") => {
      this.setState({[`${resource}Loading`]: true})
      axios.get(`/v1/${resource}${queryParam}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }).then(res => {
        this.setState({
          [`${resource}Loading`]: false,
          [`${resource}Loaded`]: true,
          // [`${resource}LastLoaded`]: new Date(),
        })
        cb(null, res);
        if (resource === "devices" || resource === "events") {
          this.setState({ [resource]: res.data.data.map(d => ({
            ...d,
            lastUpdated: new Date()
          })) });
        } else {
          this.setState({ [resource]: res.data.data });
        }
      }).catch(error => {
        this.setState({
          [`${resource}Loading`]: false,
          [`${resource}Loaded`]: true,
        })       
        cb(error, null)
      })
    }
    fetchResourceItem = (resource, id, cb = () => {}, replacementUrl) => {
      this.setState({
        [`${resource}ItemLoading`]: true
      })
      axios.get(replacementUrl || `/v1/${resource}/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }).then(res => {
        this.setState({
          [`${resource}ItemLoading`]: false
        })
        cb(null, res);
        this.setState({
          [resource]: this.state[resource].map(item => item.id == id ? (
              {
                ...res.data.data,
                lastUpdated: new Date()
              }
            ) : (
              item
            )
          )
        })
      }).catch(err => {
        this.setState({
          [`${resource}ItemLoading`]: false
        })
        cb(err, null);
        console.log(err);
      })
    }
    createResource = (
      resource, 
      body, 
      cb = () => {}, 
      replacementUrl
    ) => {
      axios.post(replacementUrl || `/v1/${resource}`, body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }).then(res => {
        cb(null, res.data.data)
        this.setState({
          [resource]: [...this.state[resource], res.data.data]
        })
        NotificationManager.success(`${resourceRef[resource]} created successfully`)

      }).catch(err => {
        console.log(err)
        NotificationManager.alert(`Error occurred creating ${resourceRef[resource]}`)

        cb(err, null);
      })
    }
    removeResource = (
      resource, 
      resourceId, 
      cb = () => {}, 
      idField="id",
      replacementUrl = null
    ) => {
      axios.delete(replacementUrl || `/v1/${resource}/${resourceId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }).then(res => {
        cb(null, res.data.data)
        this.setState({
          [resource]: this.state[resource].filter(f => f[idField] != resourceId)
        })
        NotificationManager.success(`${resourceRef[resource]} deleted successfully`)

      }).catch(err => {
        console.log(err);
        cb(err, null);
        NotificationManager.alert(`Error occurred deleting ${resourceRef[resource]}`)
      })
    }
    // fetchAndUpdateResourceItem = (resource, id, cb = ()=>{}) => {
    //   axios.get(`/v1/${resource}/${id}`)
    //     .then(res => {
    //       cb(null, res);
    //       this.setState({
    //         [resource]: this.state[resource.map()]
    //       })
    //     }).catch(err => {
    //       cb(err, null);
    //       console.log(err);
    //     })
    // }

    getDeviceItemAndData = (id, cb = () => {}) => {
      const foundDevice = this.state.devices.find(d => d.enclosureSerialNumber === id);
      if (foundDevice) {
        this.setState({ // update specific device with loading
          devices: this.state.devices.map(d => {
            if (d.enclosureSerialNumber === id) {
              return ({
                ...d,
                loading: true
              })
            } else {
              return d
            }
          })
        })
      }
      axios.get(`/v1/devices/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      })
        .then(res => {
          cb(null, res);
          const { events, outlets, inlets, banks, ...newDevice } = res.data.data;
          newDevice.lastUpdated = new Date();
          newDevice.loading = false

          const outletsArray = this.state.outlets;
          const outletsAlreadyExist = this.state.outlets.find(o => o.enclosureSerialNumber == newDevice.enclosureSerialNumber);


          this.setState({
            devices: this.state.devices.map(d => d.enclosureSerialNumber === newDevice.enclosureSerialNumber ? newDevice : d),
            outlets: this.state.outlets.find(o => o.enclosureSerialNumber == newDevice.enclosureSerialNumber) ? this.state.outlets.map(o => outlets.find(newOutlet => newOutlet.id === o.id) || o) : [...outletsArray, ...outlets],
            inlets: this.state.inlets.find(i => i.enclosureSerialNumber == newDevice.enclosureSerialNumber) ? this.state.inlets.map(i => inlets.find(newInlet => newInlet.id === i.id) || i) : [...this.state.inlets, ...inlets],
            // inlets: this.state.inlets.map(o => inlets.find(newInlet => newInlet.id === o.id) || o),
            // banks: this.state.banks.map(o => banks.find(newBank => newBank.id === o.id) || o),
            // events: this.state.events
            // TODO MAKE IT SO IT ADDS IF NOT THERE< DELETES IF NOT THERE< AND MODIFIES IF MODIFIED
            events: this.state.events.map(o => (events || []).find(newEvent => newEvent.enclosureSerialNumber+newEvent.id === o.enclosureSerialNumber+o.id) || o),
          })
        }).catch(err =>{
          cb(err, null);
          console.log(err)
        })
    }

    modifyResource = (
      resource, 
      id, 
      body, 
      cb = () => {}, 
      itemIdfield="id", 
      noNotification,
      replacementUrl
    ) => {
      axios.put(replacementUrl || `/v1/${resource}/${id}`, body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }).then(res => {
        cb(null, res.data);
        this.setState({// replace single item in array for resource being edited, search by itemIdField match for id parameter
          [resource]: this.state[resource].map(item => item[itemIdfield] == id ? {...item, ...res.data.data} : item)
        })
        if (!noNotification) {
          NotificationManager.success(`${resourceRef[resource]} updated successfully`)
        }
      }).catch(err => {
        cb(err, null);
        console.log(err)
        if (!noNotification) {
          NotificationManager.alert(`Error occurred updating ${resourceRef[resource]}`)
        }
      })
    }

    updateFetchWrapperState = (newState) => {
      this.setState(newState)
    }



    componentDidMount() {
      this.fetchResource("devices");
      this.fetchResource("groups")
      // this.fetchResource("outlets");
      // this.fetchResource("inlets");
      // this.fetchResource("events");
      // this.fetchResource("sensors");
      // check if user is enterprise admin or has_admin_privileges to fetch enterprise  by user.enterprise_Id
      if (this.props.user.enterprise_Id && (this.props.user.is_enterprise_admin || this.props.user.has_admin_privileges)) {
        this.setState({userEnterpriseLoading: true});
        axios.get("/v1/enterprises/"+this.props.user.enterprise_Id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }).then((res) => {
          this.setState({
            userEnterprise: res.data.data,
            userEnterpriseLoading: false,
            userEnterpriseLoaded: true
          });
        }) .catch((err) => {
          console.log(err);
          this.setState({
            userEnterpriseLoading: false,
            userEnterpriseLoaded: true
          });
        } );      
      }
    }


    render() {
      return (
        <WrappedComponent
          resources={this.state}
          fetchResource={this.fetchResource}
          fetchResourceItem={this.fetchResourceItem}
          modifyResource={this.modifyResource}
          removeResource={this.removeResource}
          createResource={this.createResource}
          getDeviceItemAndData={this.getDeviceItemAndData}
          updateFetchWrapperState={this.updateFetchWrapperState}
          {...this.props}
        />
      );
    }
  }
}

export default fetchWrapper
import React from "react";
import { Link } from "react-router-dom";

const columnWidths = [
  1, //
  4, // Group Name
  6, // Devices
  2, // Users
  6, // Alerts
];

export default class DeviceGroups extends React.Component {
  componentDidMount() {
    if (
      !this.props.resources.groupsLoaded &&
      !this.props.resources.groupsLoading
    ) {
      this.props.fetchResource("groups");
    }
  }
  state = {
    bulkyViewActive: false,

    searchBar: "",
    currentFilter: "",
  };
  applyFilters = () => {
    this.setState({
      currentFilter: this.state.searchBar,
    });
  };
  render() {
    const groups = this.props.resources.groups.filter(
      (g) => g.groupType === "devices"
    );
    const {
      // events,
      devices,
      // eventsLoading,
      devicesLoading,
      groupsLoading,
    } = this.props.resources;

    const filteredGroups = groups.filter((group) => {
      return `${group.name.toLowerCase()}${(
        group.description || ""
      ).toLowerCase()}`.includes(this.state.currentFilter.toLowerCase());
    });
    const isAdmin =
      this.props.user.has_admin_privileges ||
      this.props.user.is_enterprise_admin;

    if (groupsLoading) {
      return (
        <div>
          <div className={`grid-row header`}>
            {/* <div style={{ flex: columnWidths[0] }}></div> */}
            <div style={{ flex: columnWidths[1] }}>Group Name</div>
            <div style={{ flex: columnWidths[2] }}>Devices</div>
            {isAdmin && <div style={{ flex: columnWidths[3] }}>Users</div>}
            <div style={{ flex: columnWidths[4] }}>Alerts</div>
          </div>
          {[1, 2, 3, 4].map((i) => (
            <div key={i} className={`grid-row`}>
              {/* <div style={{ flex: columnWidths[0] }}></div> */}
              <div style={{ flex: columnWidths[1] }}>
                <div
                  className="skeleton-blank"
                  style={{ maxWidth: "200px", margin: "0px" }}
                ></div>
              </div>
              <div style={{ flex: columnWidths[2] }}>
                <div
                  className="skeleton-blank"
                  style={{ maxWidth: "250px", margin: "0px" }}
                ></div>
              </div>
              {isAdmin && (
                <div style={{ flex: columnWidths[3] }}>
                  <div
                    className="skeleton-blank"
                    style={{ maxWidth: "100px", margin: "0px" }}
                  ></div>
                </div>
              )}
              <div style={{ flex: columnWidths[4] }}>
                <div
                  className="skeleton-blank"
                  style={{ maxWidth: "200px", margin: "0px" }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      );
    }
    if (groups.length === 0) {
      return (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <div style={{ marginBottom: "10px" }}>
            <i
              className="material-icons"
              style={{ fontSize: "50px", color: "grey" }}
            >
              group_add
            </i>
          </div>
          <div style={{ color: "#666" }}>
            <h4 style={{ color: "#333" }}>No Device Groups Yet</h4>
            {(this.props.user.has_admin_privileges ||
            this.props.user.is_enterprise_admin) || !this.props.user.enterprise_Id ? (
              <div
                style={{
                  maxWidth: "820px",
                  margin: "auto",
                  marginBottom: "20px",
                }}
              >
                Device Groups allow you to organize your PDUs and control
                access. You can add devices to a group, assign users, and set
                permissions for each user. This helps manage multiple devices
                efficiently. Get started by creating a new group to manage your
                devices and users.
              </div>
            ) : (
              <div>
                Device Groups allow you to organize your PDUs and control
                access.
              </div>
            )}
            {(this.props.user.has_admin_privileges || this.props.user.is_enterprise_admin) || !this.props.user.enterprise_Id ? (

            <div
              style={{
                // display: "flex",
                // justifyContent: "flex-end",
                paddingBottom: "10px",
                paddingTop: "10px",
              }}
            >
              <Link to="/devices/groups/new" className="button">
                Create New Group
              </Link>
            </div>
            ):null}
          </div>
        </div>
      );
    }

    const { searchBar, currentFilter } = this.state;

    return (
      <div>
        <div
          style={{
            display: "flex",
            // justifyContent: "flex-end",
            alignItems: "center",
            paddingBottom: "10px",
            marginTop: "15px",
            marginBottom: "15px",
          }}
          className="grid-x"
        >
          <div className="cell show-for-small-only">
            <Link
              to="/devices/groups/new"
              className="button"
              style={{
                margin: "0px",
                marginBottom: "10px",
                width: "100%",
              }}
            >
              Create New Group
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // marginBottom: "10px",
              // width: "100%"
            }}
            className="cell large-8 medium-8"
          >
            <input
              type="text"
              placeholder="Search for group name or description"
              value={this.state.searchBar}
              onChange={(e) => {
                this.setState({ searchBar: e.target.value });
              }}
              style={{ margin: "0px" }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  this.applyFilters();
                }
              }}
            />
            <span
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                padding: "0.45rem",
                height: "2.43rem",
                margin: "0 0 1rem",
                borderRadius: "0.1875rem",
                backgroundColor: "#49535E",
                color: "#fff",
                marginBottom: "0px",
              }}
              onClick={() => {
                this.applyFilters();
              }}
            >
              <i className="material-icons" style={{ marginRight: "0px" }}>
                search
              </i>
            </span>
          </div>
          <div
            className="cell large-4 medium-4 hide-for-small-only"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Link
              to="/devices/groups/new"
              className="button"
              style={{
                margin: "0px",
                whiteSpace: "pre",
              }}
            >
              Create New Group
            </Link>
          </div>
        </div>
        <div className={`grid-row header`}>
          {/* <div style={{ flex: columnWidths[0] }}></div> */}
          <div style={{ flex: columnWidths[1] }}>Group Name</div>
          <div style={{ flex: columnWidths[2] }}>Description</div>
          <div style={{ flex: columnWidths[2] }}>Devices</div>
          {isAdmin && <div style={{ flex: columnWidths[3] }}>Users</div>}
          {/* <div style={{ flex: columnWidths[4] }}>Alerts</div> */}
        </div>
        {filteredGroups.map((groupObj) => {
          const groupDevices = groupObj.items
            .map((enclosureSnString) =>
              devices.find((d) => d.enclosureSerialNumber == enclosureSnString)
            )
            .filter((d) => d);
          const onlineDevices = groupDevices.filter(
            (d) => d.connectionState === "Connected"
          );
          const offlineDevices = groupDevices.filter(
            (d) => d.connectionState !== "Connected"
          );
          // const groupEvents = events.filter(eve => groupObj.items.includes(eve.enclosureSerialNumber.toString()));
          const description = groupObj.description || "--";

          return (
            <div key={groupObj.id} className={`grid-row`}>
              {/* <div style={{ flex: columnWidths[0] }}></div> */}
              <div
                style={{
                  flex: columnWidths[1],
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Link to={`/devices/groups/${groupObj.id}`}>
                  <span
                    style={{
                      textDecoration: "underline",
                      // fontWeight: "600",
                      paddingLeft: "8px",
                    }}
                  >
                    {groupObj.name}
                  </span>
                </Link>
                {/* <span>
                  {description}
                </span> */}
              </div>
              <div style={{ flex: columnWidths[2] }}>{description}</div>
              <div style={{ flex: columnWidths[2] }}>
                {devicesLoading ? (
                  <div
                    className="skeleton-blank"
                    style={{ margin: "0px", maxWidth: "250px" }}
                  />
                ) : (
                  <span>
                    <span
                      style={{
                        color: onlineDevices.length > 0 ? "forestgreen" : "",
                        marginRight: "5px",
                      }}
                    >
                      <b>{onlineDevices.length}</b> Online
                    </span>
                    <span style={{ padding: "0px 5px" }}>/</span>
                    <span
                      style={{
                        // color: "#666",
                        color: offlineDevices.length > 0 ? "maroon" : "",
                        marginRight: "5px",
                      }}
                    >
                      <b>{offlineDevices.length}</b> Offline
                    </span>
                  </span>
                )}
              </div>
              {isAdmin && (
                <div style={{ flex: columnWidths[3] }}>
                  {groupObj.users?.length || 0}
                </div>
              )}
              {/* <div style={{ flex: columnWidths[4] }}>
                {eventsLoading ? (
                  <div className="skeleton-blank" />
                ) : (
                  <Link
                    to={`/devices/groups/${groupObj.id}/alerts`}
                  >
                    <div style={{ padding: "0px" }}>
                      <i
                        className="material-icons"
                        style={{
                          color: numAlerts > 0 ? "maroon" : "forestgreen",
                          marginRight: "0px",
                        }}
                      >
                        {numAlerts > 0 ? "circle_notifications" : "task_alt"}
                      </i>

                      <span className="show-for-large-only">
                        <b>{numAlerts}</b> Alert(s)
                      </span>
                    </div>
                  </Link>
                )}
              </div> */}
            </div>
          );
        })}
      </div>
    );
  }
}

import React from "react";
import TextInput from "../common/inputs/textInput";
import ToggleInput from "../common/inputs/toggleInput";
// import NotificationManager from './common/notifications/NotificationManager';
import Loading from "../common/loading";
import Logo from "../../synaccess_white.svg";
import { Link } from "react-router-dom";

export default class Login extends React.Component {
  state = {
    username: "",
    password: "",
    usernameError: "",
    passwordError: "",
    loading: false,
    stayLoggedIn: true,
    samlLoginUrl: ""
  };
  componentDidMount() {
    const samlLoginurl = localStorage.getItem("samlLoginUrl");
    if (samlLoginurl) {
      this.setState({ samlLoginUrl: samlLoginurl });
    }
  }
  submit() {
    // console.log("Submitting")
    this.setState({
      usernameError: "",
      passwordError: "",
      loading: true,
    });

    if (!this.state.username && !this.state.password) {
      this.setState({
        usernameError: "Missing Username",
        passwordError: "Missing Password",
        loading: false,
      });
    } else if (!this.state.username) {
      this.setState({
        usernameError: "Missing Username",
        loading: false,
      });
    } else if (!this.state.password) {
      this.setState({
        passwordError: "Missing Password",
        loading: false,
      });
    } else {
      this.props.fetchLogin(
        this.state.username,
        this.state.password,
        () => {
          this.setState({
            loading: false,
          });
        },
        this.state.stayLoggedIn
      );
    }
  }
  // forgottenPassword = () => {
  //   alert("TODO");
  // };

  render() {
    const {
      username,
      password,
      usernameError,
      passwordError,
      loading,
      stayLoggedIn,
    } = this.state;

    return (
      <div
        style={{
          height: "117.7vh",
          overflow: "auto",
        }}
        className="background-gradient"
      >
        <div className="grid-container">
          <div className="grid-x grid-padding-x grid-padding-y align-center">
            <div
              className="cell large-6 medium-8 small-10"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                // className="app-item"
                style={{
                  padding: "0px",
                  marginTop: "10vh",
                  borderRadius: "2px",
                  // boxShadow: '2px 3px 3px rgba(0,0,0,0.1)',
                  maxWidth: "520px",
                  minWidth: "350px",
                  // border: "solid red 1px"
                }}
              >
                <div
                  style={{
                    // maxHeight: "60px",
                    // border: "solid red 1px"
                    paddingBottom: "20px",
                  }}
                >
                  <img alt="logo" src={Logo} width="165px" />
                </div>
                <div
                  style={{
                    // boxShadow: "2px 3px 3px rgba(0,0,0,0.15)",

                    boxShadow: "3px 3px 20px rgba(0,0,0,0.05)",
                  }}
                >
                  <div
                    style={{
                      // backgroundColor: "#1d262f",
                      padding: "30px 0px 0px 50px",
                      // color: "white",
                      borderRadius: "2px",
                      // display: 'flex',
                      // justifyContent: 'center',
                      fontSize: "125%",
                      fontWeight: "600",
                      backgroundColor: "white",
                    }}
                  >
                    Sign in to your account
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "20px 50px 30px 50px",
                      borderRadius: "3px",
                    }}
                  >
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        this.submit();
                      }}
                    >
                      <TextInput
                        label="Email"
                        placeholder="Email"
                        value={username}
                        error={usernameError}
                        callback={(data) => this.setState({ username: data })}
                        tabIndex="1"
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextInput
                          label="Password"
                          value={password}
                          error={passwordError}
                          type="password"
                          placeholder="Password"
                          callback={(data) => this.setState({ password: data })}
                          inputContainerStyle={{
                            width: "100%",
                          }}
                          tabIndex="2"
                          labelMate={
                            <div
                              style={{
                                color: "#223647",
                                cursor: "pointer",
                                // fontWeight: "600"
                              }}
                              // onClick={() => {
                                // this.forgottenPassword();
                              // }}
                            >
                              <Link
                                to="/reset"

                                tabIndex="-1"
                              >
                                Forgot your password?

                              </Link>
                            </div>
                          }
                        />
                      </div>
                      {this.props.errMessage && (
                        <div
                          style={{
                            padding: "10px 0px 20px 0px",
                            color: "maroon",
                          }}
                        >
                          {this.props.errMessage}
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingBottom: "25px",
                          paddingTop: "10px",
                        }}
                      >
                        <ToggleInput
                          value={stayLoggedIn}
                          callback={() =>
                            this.setState({ stayLoggedIn: !stayLoggedIn })
                          }
                          inputContainerStyle={{
                            margin: "0px",
                            marginRight: "10px",
                          }}
                          id="toggle_login"
                        />
                        <div>Stay signed in for a week</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingBottom: "20px",
                        }}
                      >
                        <button
                          className="button"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: "600",
                            width: "100%",
                            boxShadow: "3px 3px 3px rgba(0,0,0,0.1)",
                            color: "white",
                          }}
                          // onClick={() => this.submit()}
                          type="submit"
                        >
                          {loading ? <Loading /> : "Continue"}
                        </button>
                      </div>
                    </form>
                    {this.state.samlLoginUrl && (
                      <div
                        style={{
                          borderTop: "1px solid #e0e0e0",
                        }}
                      >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "20px",
                        }}
                      >
                        <button
                          className="button"
                          onClick={() => {
                            window.location.href = this.state.samlLoginUrl;
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: "600",
                            width: "100%",
                            boxShadow: "3px 3px 3px rgba(0,0,0,0.1)",
                            // color: "white",
                            backgroundColor: "white",
                            color: "#223647",
                            border: "1px solid rgba(34,54,71,0.45)",
                          }}
                          // onClick={() => this.submit()}
                        >
                          Sign in with SAML
                        </button>
                      </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    padding: "20px 10px 10px 10px",
                    color: "white",
                  }}
                >
                  Don't have an account?{" "}
                  <Link to="/register">
                    <b style={{ color: "white" }}>Sign up</b>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
